export enum CampaignPermission {
    READ = "campaign:read",
    WRITE = "campaign:write",
}

export enum LeadsPermission {
    READ = "leads:read",
    REPROCESS = "leads:reprocess",
}

export enum MSOPipelinesPermission {
    READ = "mso:pipelines:read",
    RETRY = "mso:pipelines:retry",
}

export enum MSOTasksPermission {
    READ = "mso:tasks:read",
}

export enum OrganizationPermission {
    READ = "organizations:read",
    WRITE = "organizations:write",
}

export enum ChannelsPermission {
    READ = "channels:read",
    WRITE = "channels:write",
}

export enum UserPermission {
    READ = "user:read",
}

export enum OrganizationSenderNamePermission {
    READ = "organizations.sender-name:read",
    WRITE = "organizations.sender-name:write",
}

export enum MessagingTriggersPermission {
    READ = "triggers:read",
    WRITE = "triggers:write",
    DELETE = "triggers:delete",
}

export enum ApplicationStatusesPermission {
    READ = "applications.statuses:read",
}

export enum OrganizationTemplatesPermission {
    READ = "organizations.templates:read",
    WRITE = "organizations.templates:write",
}

export enum AutomationPermission {
    WRITE = "automation:write",
}

export type Permission =
    | CampaignPermission
    | LeadsPermission
    | MSOPipelinesPermission
    | MSOTasksPermission
    | OrganizationPermission
    | UserPermission
    | OrganizationSenderNamePermission
    | ChannelsPermission
    | MessagingTriggersPermission
    | ApplicationStatusesPermission
    | OrganizationTemplatesPermission
    | AutomationPermission;
