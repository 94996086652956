"use client";

import Link from "next/link";

import { useEffect, useState } from "react";

import { User } from "next-auth";
import { signOut } from "next-auth/react";
import { useServerAction } from "zsa-react";

import { getUserInfos } from "@lib/actions/user";
import {
    CampaignPermission,
    ChannelsPermission,
    LeadsPermission,
    MSOPipelinesPermission,
    MSOTasksPermission,
    OrganizationPermission,
} from "@lib/auth/scopes";

import { Button } from "./ui/button";
import {
    Sidebar,
    SidebarContent,
    SidebarFooter,
    SidebarGroup,
    SidebarGroupContent,
    SidebarGroupLabel,
    SidebarHeader,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
} from "./ui/sidebar";

export default function AppSidebar() {
    const [userInfos, setUserInfos] = useState<User | null>(null);

    const { execute, isPending, isSuccess, isError } = useServerAction(getUserInfos);

    useEffect(() => {
        async function fetchUserInfos() {
            const [fetchedUserInfos, error] = await execute();
            setUserInfos(fetchedUserInfos);

            if (error) {
                console.error(error);
            }
        }
        fetchUserInfos().catch(console.error);
    }, [execute]);

    return (
        <Sidebar>
            <SidebarHeader></SidebarHeader>
            <SidebarContent>
                <SidebarGroup permissions={[LeadsPermission.READ, LeadsPermission.REPROCESS]}>
                    <SidebarGroupLabel>FB Leads</SidebarGroupLabel>
                    <SidebarGroupContent>
                        <SidebarMenu>
                            <SidebarMenuItem permissions={[LeadsPermission.REPROCESS]}>
                                <SidebarMenuButton asChild>
                                    <Link href="/fb_leads/reprocess">Re-import</Link>
                                </SidebarMenuButton>
                            </SidebarMenuItem>
                            <SidebarMenuItem permissions={[LeadsPermission.READ]}>
                                <SidebarMenuButton asChild>
                                    <Link href="/fb_leads/list">List</Link>
                                </SidebarMenuButton>
                            </SidebarMenuItem>
                        </SidebarMenu>
                    </SidebarGroupContent>
                </SidebarGroup>

                <SidebarGroup
                    permissions={[MSOPipelinesPermission.READ, MSOPipelinesPermission.RETRY, MSOTasksPermission.READ]}
                >
                    <SidebarGroupLabel>MSO</SidebarGroupLabel>
                    <SidebarGroupContent>
                        <SidebarMenu>
                            <SidebarMenuItem permissions={[MSOPipelinesPermission.READ, MSOPipelinesPermission.RETRY]}>
                                <SidebarMenuButton asChild>
                                    <Link href="/mso/listPipelines">Failed pipelines</Link>
                                </SidebarMenuButton>
                            </SidebarMenuItem>
                            <SidebarMenuItem permissions={[MSOTasksPermission.READ]}>
                                <SidebarMenuButton asChild>
                                    <Link href="/mso/listTasks">Visualize by ID</Link>
                                </SidebarMenuButton>
                            </SidebarMenuItem>
                        </SidebarMenu>
                    </SidebarGroupContent>
                </SidebarGroup>

                <SidebarGroup permissions={[OrganizationPermission.WRITE]}>
                    <SidebarGroupLabel>Organizations</SidebarGroupLabel>
                    <SidebarGroupContent>
                        <SidebarMenu>
                            <SidebarMenuItem permissions={[OrganizationPermission.WRITE]}>
                                <SidebarMenuButton asChild>
                                    <Link href="/organizations/new">Create</Link>
                                </SidebarMenuButton>
                            </SidebarMenuItem>
                            <SidebarMenuItem permissions={[OrganizationPermission.READ]}>
                                <SidebarMenuButton asChild>
                                    <Link href="/organizations">List</Link>
                                </SidebarMenuButton>
                            </SidebarMenuItem>
                        </SidebarMenu>
                    </SidebarGroupContent>
                </SidebarGroup>

                <SidebarGroup permissions={[CampaignPermission.WRITE]}>
                    <SidebarGroupLabel>Sourcing operations</SidebarGroupLabel>
                    <SidebarGroupContent>
                        <SidebarMenu>
                            <SidebarMenuItem permissions={[CampaignPermission.WRITE]}>
                                <SidebarMenuButton asChild>
                                    <Link href="/campaigns/new">Create</Link>
                                </SidebarMenuButton>
                            </SidebarMenuItem>
                            <SidebarMenuItem permissions={[CampaignPermission.READ]}>
                                <SidebarMenuButton asChild>
                                    <Link href="/campaigns/list">List</Link>
                                </SidebarMenuButton>
                            </SidebarMenuItem>
                        </SidebarMenu>
                    </SidebarGroupContent>
                </SidebarGroup>

                <SidebarGroup permissions={[ChannelsPermission.READ]}>
                    <SidebarGroupLabel>Channel</SidebarGroupLabel>
                    <SidebarGroupContent>
                        <SidebarMenu>
                            <SidebarMenuItem permissions={[ChannelsPermission.WRITE]}>
                                <SidebarMenuButton asChild>
                                    <Link href="/channels/new">Create</Link>
                                </SidebarMenuButton>
                            </SidebarMenuItem>
                            <SidebarMenuItem permissions={[ChannelsPermission.READ]}>
                                <SidebarMenuButton asChild>
                                    <Link href="/channels/list">List</Link>
                                </SidebarMenuButton>
                            </SidebarMenuItem>
                        </SidebarMenu>
                    </SidebarGroupContent>
                </SidebarGroup>
            </SidebarContent>

            <SidebarFooter>
                {isPending && <p>Loading...</p>}
                {isSuccess && <p>Welcome {userInfos?.name}</p>}
                {isError && <p style={{ color: "red" }}>Failed to retrieve user information</p>}
                <Button onClick={() => signOut().catch(console.error)}>Logout</Button>
            </SidebarFooter>
        </Sidebar>
    );
}
